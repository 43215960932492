import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import HubspotForm from "react-hubspot-form"

export default function AIChecklist() {
  return (
    <Layout
      title="Download our AI Readiness checklist"
      keywords="download ai checklist, ai readiness"
      description="Download our AI Readiness checklist to see if your customer support team is ready for Generative AI"
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <script
          charSet="utf-8"
          src="https://js.hsforms.net/forms/shell.js"
        ></script>
        <link
          rel="canonical"
          href="https://irisagent.com/ai-checklist/"
        />
      </Helmet>

      <section className="inner-page" style={{ margin: "2% 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <h1>Is your customer support team Generative AI-ready? Download our AI Readiness checklist for free.</h1>
                <br />
                <p>
                  Customer support teams aim to scale globally and automate their operations. The adoption of Generative AI is on the rise, but like every technology it is important to ensure whether your organization is ready.<br/><br/>

                    Hence we have created an AI readiness checklist just for you to help you in the same which you can download for free. <br/><br/>
                    The checklist is built considering all the aspects before implementing Generative AI from strategic planning, Technical and operational preparation, Implementation and Scaling, and Performance evaluation. By ensuring the following steps you may ensure that your organization is ready to implement Generative AI like IrisAgent’s IrisGPT and much more. <br/><br/>
                    Download the checklist today!
                </p>
                <img
                  src="/img/ai_checklist.png"
                  className="img-fluid"
                  alt="Download AI Checklist"

                />
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                {/* <HubspotForm
                  portalId='7494256'
                  formId='29f4e374-b150-48db-9a15-668eeac19625'
                  loading={<div>Loading...</div>}
                /> */}
                <HubspotForm
                  portalId='7494256'
                  formId='bcab3132-bc48-47e3-a5b2-6c2952c2e016'
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
